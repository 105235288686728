export function useNft() {
  function handlePresentNFTIcon(id, network) {
    return !network || network === 'Arbitrum'
      ? new URL(`../assets/img/nft/arbitrum-${id}.png`, import.meta.url).href
      : new URL(`../assets/img/nft/${network.toLowerCase()}-${id}.png`, import.meta.url).href;
  }

  return {
    handlePresentNFTIcon,
  };
}
